import $ from 'jquery';

export default function () {

	var menuOuvert = false;

	$(window).scroll(function () {
		$('.c-mobile-menu').removeClass('active');
	});

	$('#hamburger-icon').click(function (event) {
		event.stopPropagation();
		if (!menuOuvert) {
			$('.c-mobile-menu').addClass('active');
			menuOuvert = true;
		}
		else {
			$('.c-mobile-menu').removeClass('active');
			menuOuvert = false;
		}
		event.stopPropagation();
	});

	$('body').click(function () {
		if (menuOuvert) {
			$('.c-mobile-menu').removeClass('active');
			menuOuvert = false;
		}
	});

}
